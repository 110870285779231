import React, { useState } from 'react';

import SEO from 'components/SEO';

import fugenImage from './images/fugen.png';
import abdichtungenImage from './images/abdichtungen.png';
import sanierungenImage from './images/sanierungen.png';
import speziellesImage from './images/spezielles.png';

const serviceImages = {
  fugen: fugenImage,
  abdichtungen: abdichtungenImage,
  sanierungen: sanierungenImage,
  spezielles: speziellesImage,
};

function Service(props) {
  const [option, setOption] = useState(0);

  const {
    title,
    options = [],
    left = false,
    right = false,
  } = props;

  const titleSlug = title.toLowerCase().replace(/[^a-z0-9]/gi, '_');
  const illustrationImg = serviceImages[titleSlug];
  const illustrationOffset = option * (100 / options.length);

  const classNames = [
    'service',
    left && 'left',
    right && 'right',
  ];

  return (
    <div className={classNames.filter(c => !!c).join(' ')}>
      <h1>{title}</h1>
      <div className="service-body">
        <div className="illustration">
          <div id={`${title}-slide`} className="slide" style={{ width: `${options.length * 195}px`, transform: `translateX(-${illustrationOffset}%)` }}>
            <img src={illustrationImg} alt="illustration" />
          </div>
        </div>
        <ul className="options">
          {options.map((desc, index) => (
            <li
              key={desc}
              className="option"
            >
              <span
                className={`link-ish ${option === index ? 'active' : ''}`}
                tabIndex="-1"
                role="radio"
                aria-checked={option === index}
                onKeyPress={() => {}}
                onClick={() => { setOption(index); }}
              >
                {desc}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const DienstleistungenPage = () => (
  <>
    <SEO title="Dienstleistungen" keywords={[]} />
    <Service
      left
      title="Fugen"
      options={[
        'Innenfugen im Sanitärbereich',
        'Aussenfugen im Hoch- und Tiefbau',
        'Fugenabdichtung in Fenster- und Fassadenbau',
      ]}
    />
    <Service
      right
      title="Abdichtungen"
      options={[
        'Spezialabdichtungen unter Terain',
        'Fugenabdichtung in Fenster- und Fassadenbau',
      ]}
    />
    <Service
      left
      title="Sanierungen"
      options={[
        'Sanierungen & Reparaturen',
      ]}
    />
    <Service
      right
      title="Spezielles"
      options={[
        'Ausschäumungen genen Wärmeverluste',
        'Spezialabdichtungen unter Terain',
      ]}
    />
  </>
);

export default DienstleistungenPage;
